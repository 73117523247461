import { Link } from "react-router-dom";
import "../styles/Footer.css"; // Import CSS file for styling

// Coming Soon: iOS Google Play Store

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-columns">
        <div className="footer-column">
          <h3>Legal</h3>
          <ul>
            <li>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li>
              <Link to="/terms-and-conditions">Terms & Conditions</Link>
            </li>
            <li>
              <Link to="/intellectual-property">Intellectual Property</Link>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Socials</h3>
          <ul>
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/one_date.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://www.tiktok.com/@one_date.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  TikTok
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/@one_date_app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  YouTube
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61565110851897"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://x.com/one_date_app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  X
                </a>
              </li>
              <li>
                <a
                  href="https://www.lemon8-app.com/one_date.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Lemon8
                </a>
              </li>
              <li>
                <a
                  href="https://www.threads.net/@one_date.app"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Threads
                </a>
              </li>
            </ul>
          </ul>
        </div>
        <div className="footer-column">
          <h3>What's More</h3>
          <ul>
            <li>
              <Link to="/faqs">FAQs</Link>
            </li>
            <li>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-line">© 2024 One Date • All Rights Reserved</div>
    </footer>
  );
};

export default Footer;
